import {CommonModule} from '@angular/common'
import {NgModule} from '@angular/core'
import {ReactiveFormsModule} from '@angular/forms'
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {MatButtonModule} from '@angular/material/button'
import {MatDialogModule} from '@angular/material/dialog'
import {MatIconModule} from '@angular/material/icon'
import {MatInputModule} from '@angular/material/input'
import {MatMenuModule} from '@angular/material/menu'
import {RouterLink} from '@angular/router'
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component'
import {HeaderComponent} from './header/header.component'
import {WaitDialogComponent} from './wait-dialog/wait-dialog.component'
import {PluralPipe} from './plural.pipe'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'

@NgModule({
  declarations: [
    HeaderComponent,
    BreadcrumbsComponent,
    WaitDialogComponent,
    PluralPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    ThemeModule,
    RouterLink
  ],
  exports: [
    HeaderComponent,
    BreadcrumbsComponent,
    WaitDialogComponent,
    PluralPipe
  ]
})
export class SpbCommonModule {
}
